import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { genSwitchCalendarShow, genChangeCurrentMonth, genChangeShowTrend } from '../../../../actions/list/calendarLowprice'
import { genSwitchIncludeTax } from '../../../../actions/list/sort'
import { getMonthsCalendar } from './calendarLPSelector.js'
import CalendarLowPriceBody from '../../../../components/list/top/calendarLowprice/calendarLowPriceBody.js'
import { getToRememberActiveKeysSelector, globalSwitchSelector } from '../../result/flight/baseSelector';

class SingleCalendarContainer extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        //初始化各种数据
        let searchCriteria = this.props.searchCriteria,   //搜索条件
            searchedDay = moment(searchCriteria.getIn(["flightSegments", 0, "departureDate"])),  //搜索当天日期
            searchedMonthFirst = moment([searchedDay.year(), searchedDay.month(), 1]),  //搜索月份的第一天
            todayMonthFirst = moment([moment().year(), moment().month(), 1]), //当前月份第一天
            diffMonths = searchedMonthFirst.diff(todayMonthFirst, "months") //搜索月份差（用于初始化月视图位置）
        diffMonths = diffMonths > 0 ? diffMonths : 0
        this.props.changeCurrentMonth(diffMonths)           //初始化月份差
    }
    render() {
        let { switchCalendarShow, searchCriteria, containsTax, changeCurrentMonth, singleCalendar, currentMonthIndex, switchIncludeTax, toRememberActiveKeys,isTrend,changeShowTrend,globalSwitch} = this.props
        return (
            <CalendarLowPriceBody flightWay="S" isTrend={isTrend} switchShow={switchCalendarShow} searchCriteria={searchCriteria} containsTax={containsTax} changeCurrentMonth={changeCurrentMonth}
                singleCalendar={singleCalendar} currentMonthIndex={currentMonthIndex} switchIncludeTax={switchIncludeTax} toRememberActiveKeys={toRememberActiveKeys} changeShowTrend={changeShowTrend}
                globalSwitch={globalSwitch} 
            />
        )
    }
}

const mapStateToProps = (state) => ({
    singleCalendar: getMonthsCalendar(state),
    searchCriteria: state.getIn(['list', 'search', 'prevCond']),
    currentMonthIndex: state.getIn(["list", "calendar_lowprice", "currentMonthIndex"]),
    containsTax: state.getIn(["list", "sort", "containsTax"]),
    isTrend:state.getIn(["list", "calendar_lowprice", "isTrend"]),
    toRememberActiveKeys: getToRememberActiveKeysSelector(state),
    globalSwitch: globalSwitchSelector(state)
})

const mapDispatchToProps = dispatch => ({
    switchCalendarShow: (isShow) => {
        dispatch(genSwitchCalendarShow(isShow))
    },
    changeCurrentMonth: (currentMonthIndex) => {
        dispatch(genChangeCurrentMonth(currentMonthIndex))
    },
    switchIncludeTax: () => {
        dispatch(genSwitchIncludeTax())
    },
    changeShowTrend: (showTrend) => {
        dispatch(genChangeShowTrend(showTrend))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleCalendarContainer)